import React, {useContext, useState,useEffect} from 'react'
import {Box,Button,FormControl,Select,MenuItem } from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import Filter1TwoToneIcon from '@mui/icons-material/Filter1TwoTone';
import Filter2TwoToneIcon from '@mui/icons-material/Filter2TwoTone';
import Filter3TwoToneIcon from '@mui/icons-material/Filter3TwoTone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Dialogos} from './Dialogos'
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import {AlertaPorPagos} from './AlertaPorPagos'
import {useHelper} from '../hooks/useHelper'
import {Alert} from '@mui/material'
import { lib } from 'crypto-js';

export const TiposPago = ()=>{
    const { tipoParticipante,eventosValor_no_usar,buscarEventosValorPorEvento,crearPreferencia} = useContext(formularioContext)
    const [tipoPago,setTipoPago]= useState('mercadopago') // ATENCIÓN: Si hubiera varios tipos de pago y no solo mercadopago habría que dejar en null esta prop
    const {formatearMontoMoneda}= useHelper()
    const [mostrarMensaje,setMostrarMensaje]= useState(false)
    const [url,setUrl]= useState(null)
    const [eventosValor,setEventosValor]= useState([])
    const [cantidad,setCantidad]= useState(1)
    const [generarPreferencia,setGenerarPreferencia]= useState(false)
    const [itemValorPreferencia,setItemValorPreferencia]= useState(null)

    useEffect(()=>{

        // antes traía eventosvalor del contexto pero ahora lo busco cada vez que se entra a esta
        // vista para que si se actualizó el link u otro detalle se actualice en linea
        buscarEventosValorPorEvento()
        .then(eventosvalor=>{
            setEventosValor(eventosvalor)
        })
        .catch(err=>{
            alert(err.message)
        })

    },[])

    useEffect(()=>{
        if(false){
            setTimeout(() => {
                setMostrarMensaje(false)
            }, 5000);
        }
    },[mostrarMensaje])

    useEffect(()=>{
        setCantidad(Number(cantidad))
    },[cantidad])

    const abrirMercadoPago=()=>{
        if(url){
            window.open(url,'_self')
        }else{
            alert('El link de mercado pago es inválido')
            setMostrarMensaje(false)
        }
    }

    const abrirMensaje = (item)=>{

        if(item.link_mp){
            setUrl(item.link_mp)
            setGenerarPreferencia(false)
            setItemValorPreferencia(null)
            setMostrarMensaje([true,item.info_adicional])
        }else{
            alert(JSON.stringify(item))
            setGenerarPreferencia(true)
            setItemValorPreferencia(item)
        }
       
    }

    const iniciarPreferencia = async ()=>{
        if(cantidad>0 && itemValorPreferencia.valor>0){
            const resultado = await crearPreferencia(itemValorPreferencia.id_evento_valor,cantidad)
            window.open(resultado,'_self')
        }
    }

    const cancelarMensaje = ()=>{
        setMostrarMensaje(false)
        setGenerarPreferencia(false)
        setItemValorPreferencia(null)
        setCantidad(1)
        setUrl(null)
    }

    const handleChangeCantidad = (e)=>{

      setCantidad(e.target.value)
    }

    return  <Box sx={{display:'flex',flexDirection:'column'}}>
                <Box sx={{padding:'0.5rem'}}>
                    <Button onClick={()=>setTipoPago('mercadopago')} variant='contained' fullWidth color='primary'>Mercadopago</Button>
                </Box>
                {/*<Box sx={{padding:'0.5rem'}}>
                    <Button onClick={()=>setTipoPago('transferencia')} variant='contained' fullWidth color='primary'>Transferencia bancaria</Button>
                </Box>*/}
                <AlertaPorPagos/>
     
        <Dialogos open={tipoPago=='mercadopago'} 
                    titulo='Mercadopago' 
                    procesarCancelar = {()=>setTipoPago(null)}
                    >
                    {/* https://www.mercadopago.com.ar/checkout/v1/payment/redirect/9993de68-2f0c-4dff-a3a6-a5733fc36e4b/review/?preference-id=386717205-dd372a90-92fd-433b-9cdc-fa02ef38226f&correlation_id=b6ea5840-40cb-4015-86e7-f9c7b5c892dc&sniffing-rollout=sniffing-api&router-request-id=f309234f-98e4-4b99-b329-8fb6fb37e6d4&p=6dc44f4b7387dcabcc470d0e15dc5e62 */}

                    {eventosValor.filter(item=>tipoParticipante=='general' ? !item.contingente : item.contingente==true).map(item=>
                    <>
                        <Box sx={{padding:'0.5rem'}} style={{zoom:item?.zoom ? item?.zoom : ''}}>
                            <Button
                                //href={item.link_mp}
                                onClick={()=>abrirMensaje(item)}
                                fullWidth 
                                size="large"
                                variant='contained' 
                                color='primary'>
                                    <div style={{minHeight:'50px'}}> 
                                    {/*Hacemos este layout para que quede centrado y los botones iguales si alguno tiene info_adicional 
                                        También el replaceAll es para quitar de la descripción el identificador #NN que usamos
                                        para mapear un item valor de la tabla con un link de pago.No lo mostramos al usuario en el botón
                                    */}
                                        
                                        {!item.info_adicional && <p>
                                            {item.detalle.replaceAll(/#([0-9]{2}) - /g,"")}  - $ {formatearMontoMoneda(item.valor)}
                                        </p>}
                                        {item.info_adicional && <span>
                                            {item.detalle.replaceAll(/#([0-9]{2}) - /g,"")}  - $ {formatearMontoMoneda(item.valor)}
                                        </span>}
                                        {item.info_adicional && <div style={{display:'flex'}}>
                                            <InfoOutlinedIcon sx={{ fontSize: 25,color:'yellow' }} /> 
                                            {!item?.info_adicional.includes(';') && <span style={{color:'yellow',display:'inline-block'}}>{item.info_adicional}</span>}
                                            {item?.info_adicional.includes(';') && <div style={{display:'flex',flexDirection:'column'}}>
                                                    {crearInfoAdicionalMultipleFirst(item?.info_adicional)}
                                            </div>}
                                        </div> }
                                    </div>
                                </Button>
                                {item.info_adicional && <div style={{display:'flex'}}>
                                            {item?.info_adicional.includes(';') && <div style={{display:'flex',flexDirection:'column'}}>
                                                    {crearInfoAdicionalMultipleRest(item?.info_adicional)}
                                            </div>}
                                </div> }
                        </Box>
                        </>
                    )}    
               
                <AlertaPorPagos/>
        </Dialogos>
        <Dialogos open={tipoPago=='transferencia'} 
                    titulo='Transferencia bancaria' 
                    texto='Siga las instrucciones' 
                    procesarCancelar = {()=>setTipoPago(null)}
                    >
                <p>Los datos bancarios son:</p>
                <AlertaPorPagos/>
        </Dialogos>
        <Dialogos open={mostrarMensaje!=false} 
                    titulo='Atención' 
                    textoAceptar="Entendido"
                    procesarResultado={abrirMercadoPago}
                    texto='Luego de pagar debe regresar para completar sus datos y concretar la inscripción' 
                    procesarCancelar = {cancelarMensaje}
                    >
                       
                    <AlertaPorPagos/>
                    {mostrarMensaje[1]  &&  <Alert style={{marginTop:'10px',background:'rgb(2, 136, 209)'}} variant="filled" severity="info">
                                RECUERDE: 
                                {!mostrarMensaje[1].includes(";") && <span className='blink ml-10 color-yellow' >
                                     {mostrarMensaje[1]}
                                </span>}
                                {mostrarMensaje[1].includes(";") && <div>
                                    <ul>
                                        {/*Es necesario el replace, caso contrario por cada coma (",") se toma como un item independiente del array al hacer el split*/}
                                        {mostrarMensaje[1].replace(",","&").split(";").map(item=><li style={{color:'yellow'}}>
                                            {item.replace("&",",")}
                                        </li>)}
                                    </ul>
                                           
                                </div>}
                    </Alert>}
        </Dialogos>
        <Dialogos open={generarPreferencia} 
                    titulo={itemValorPreferencia?.detalle} 
                    textoAceptar="Realizar el pago"
                    procesarResultado={iniciarPreferencia}
                    texto='Seleccione el número de personas a inscribir' 
                    procesarCancelar = {cancelarMensaje}
                    >
                  <FormControl fullWidth>
                        {/*<InputLabel id="demo-simple-select-label" color="secondary" variant='outlined'>País</InputLabel>*/}
                        <Select fullWidth
                            labelId="select-cantidad"
                            id="sl-sexo"
                            value={cantidad}
                            label="Cantidad de personas"
                            onChange={handleChangeCantidad}
                        >
                            {vectorNumeros().map(item=>{
                                return <MenuItem value={item}>{item}</MenuItem>
                            })}
                        </Select>
                        {cantidad > 0 && itemValorPreferencia?.valor > 0 && <Alert sx={{justifyContent:'center',marginTop:'1rem'}} severity="info" variant='outlined'>
                            <p>Número de personas: {cantidad}</p>
                            <p>Importe por persona: $ {formatearMontoMoneda(itemValorPreferencia?.valor)}</p>
                            <p>Total: $ {formatearMontoMoneda(Number(cantidad * itemValorPreferencia?.valor))}</p>
                        </Alert>}
                    </FormControl>
        </Dialogos>
    </Box>
    
}

const vectorNumeros = ()=>{
    let array = []

    for(let i=0;i<100;i++){
        array.push(i+1)
    }

    return array
}

export function formatearMontoMoneda(monto){
    return new Intl.NumberFormat('de-DE',{minimumFractionDigits : 2}).format(monto.toFixed(2))
}

const crearInfoAdicionalMultipleFirst = (texto)=>{
/*
En el campo info_adicional se puede poner 1 texto simple o varios textos separados por punto y coma (";")
Es necesario el replace, caso contrario por cada coma (",") se toma como un item independiente del 
array al hacer el split*/

const arraytexto = texto.replace(",","&").split(';');

return <div style={{position:'relative'}}>
    <span style={{color:'yellow',display:'inline-block'}}>{arraytexto[0].replace("&",",")}</span>
    
    {/*<ul style={{background:'tomato',position:'absolute'}}>
        {arraytexto.filter((i,index)=>index>0).map(item=><li style={{textTransform:'none',fontSize:'smaller',color:'black'}}>
            {item.replace("&",",")}
        </li>)}
    </ul>*/}
</div>
//    .map((i,index)=><span style={index==0 ? {color:'yellow',display:'inline-block'} : {textTransform:'none'}}>{i.replace("&",",")}</span>)


}

const crearInfoAdicionalMultipleRest = (texto)=>{
    /*
    En el campo info_adicional se puede poner 1 texto simple o varios textos separados por punto y coma (";")
    Es necesario el replace, caso contrario por cada coma (",") se toma como un item independiente del 
    array al hacer el split*/
    
    const arraytexto = texto.replace(",","&").split(';');
    
    return <div style={{position:'relative'}}>
        {/*<span style={{color:'yellow',display:'inline-block'}}>{arraytexto[0].replace("&",",")}</span>*/}
        
        <ul style={{background:'tomato',borderRadius:'10px',marginTop:'-5px',marginLeft:'auto',width:'90%'}}>
            {arraytexto.filter((i,index)=>index>0).map(item=><li style={{textTransform:'none',fontSize:'smaller',color:'black',padding:'5px'}}>
                {item.replace("&",",")}
            </li>)}
        </ul>
    </div>
    //    .map((i,index)=><span style={index==0 ? {color:'yellow',display:'inline-block'} : {textTransform:'none'}}>{i.replace("&",",")}</span>)
    
    
    }