import React, {useContext,useEffect,useState} from 'react'
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import Axios from 'axios';
import {DatosPersonales} from './DatosPersonales';
import {Inscripcion} from './Inscripcion';
import { TextField,Typography,RadioGroup,Radio,FormControlLabel,Button,Box,Grid,Switch} from "@material-ui/core";
import {CardActions,CardContent,Card,CardHeader} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import Swal from 'sweetalert2';
import {Dialogos} from './Dialogos'
import {DatosPersonalesMinExterno} from './DatosPersonalesMinExterno'
import {Alert} from '@mui/material'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/EditOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useHelper} from '../hooks/useHelper'
import {Loader} from './Loader'
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const regex_solo_numeros = /^[0-9\b]+$/;

export const NuevaInscripcion = ({id_evento,eventoSeleccionado})=>{
//386717205-dd372a90-92fd-433b-9cdc-fa02ef38226f

    const { datos,
        handleChangeMinistroUad,
        datosPagoOK,
        descripcionProvincia,
        handleChangeDocumento,
        infoMinistro,
        reinicializar,
        reinicializarConyuge,
        buscarDatosMinistro,
        buscarHabilitacionExterno,
        handleChangeConyugeMinistroUad,
        datosConyugeOk,
        datosMinistroOk,
        setIdEvento,
        handleChangeProvincia,
        inscribir,generarPreferencia} = useContext(formularioContext)
        const [iniciarBusquedaDatos,setIniciarBusquedaDatos]= useState(false)
        const [datosMinistroUad,setDatosMinistroUad]= useState(null)
        const [pedirDatosConyuge,setPedirDatosConyuge] = useState(false)
        const [pedirDatosMinExterno,setPedirDatosMinExterno] = useState(false)
        const {scrollBottom}= useHelper()

    initMercadoPago('TEST-5ed39bd9-38bc-4a8c-a59f-762da2a1c3cb');

    useEffect(()=>{
        setIdEvento(id_evento)
    },[id_evento])

    useEffect(()=>{
        setIniciarBusquedaDatos(false)
        setPedirDatosMinExterno(false)
    },[datos.ministroUad])


    const cancelarBusquedaDatos = ()=>{
        setIniciarBusquedaDatos(false)
        setPedirDatosMinExterno(false)
        reinicializar()
    }


const asdfasf = {nombre:'Pablo',apellido:'Stolarczuk',documento:"123456",sexo:'M',edad:50,id_provincia:13,email:'pablo.david.st@gmail.com'}

const validarDNI = ()=>{
    buscarDatosMinistro() // sea ministro uad o no voy a verificar que el DNI está en la base de datos
    .then(info=>{
        if(!info){
            if(datos.ministroUad=='ministrouad'){
                Swal.fire({
                    html:'<p>No encontramos el DNI en la base de datos UAD</p><p>Verifique que el número es correcto</p><p>Si posee credenciales UAD y sus datos no aparecen contacte a la mesa de ayuda</p>',
                    icon: 'warning',
                    customClass: 'slow-animation',
                    confirmButtonColor: '#3085d6',
                })   
            }else{
                buscarHabilitacionExterno(datos?.nro_opcion)
                .then(resultado=>{
                    if(resultado){
                        setPedirDatosMinExterno(true)
                    }else{
                        Swal.fire({
                            html:'<p>El DNI ingresado no se encuentra autorizado</p><p>Verifique que el número es correcto</p><p>Si su DNI debiera figurar como autorizado por favor contacte a la mesa de ayuda</p>',
                            icon: 'warning',
                            customClass: 'slow-animation',
                            confirmButtonColor: '#3085d6',
                        })  
                    }
                }).catch(err=>alert(err))
            }
        }else{
            if(datos.ministroUad=='ministrouad'){
                setDatosMinistroUad(info)
                setIniciarBusquedaDatos(true) // Avanzar al próximo paso para validar datos como ministro uad
            }else{
                Swal.fire({
                    html:`<p>El DNI ingresado corresponde a un ministro UAD con credenciales (${info.nombre})</p>`,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                })   
            }
        }
    })
    .catch(err=>{
        Swal.fire({
            html:`<p>${err.message}</p>`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
        }) 
    })
}


const abrirFormConyuge = ()=>{
    setPedirDatosConyuge(true)
}

const abrirFormExterno = ()=>{
    setPedirDatosMinExterno(true)
}

const procesarMinistroExterno = ()=>{
    if(datosMinistroOk()[0]){
        setPedirDatosMinExterno(false)    
        setIniciarBusquedaDatos(true)
        setTimeout(() => {
            scrollBottom()
        }, 1000);
    }else{
        Swal.fire({
            html:`<p>${datosMinistroOk()[1]}</p>`,
            icon: 'warning',
            customClass: 'slow-animation',
            confirmButtonColor: '#3085d6',
        })   
    }
}

const processHandleChangeDNI = (e)=>{
    handleChangeDocumento(e)
    if(e.target.value.length>7){
        scrollBottom()
    }
}

return <>
        {/* <p style={{wordWrap:'break-word',width:'100%'}}>{JSON.stringify(datos)}</p> */}
        { !iniciarBusquedaDatos && <>
        <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">Para continuar indique si tiene credenciales UAD. Luego ingrese su DNI</Typography>
        <RadioGroup
                    aria-labelledby="lbl-min-uad"
                    name="radio-min-uad"
                    value={datos.ministroUad}
                    onChange={handleChangeMinistroUad}>
                       <FormControlLabel value={'ministrouad'} control={<Radio />} label="Si, tengo credenciales UAD" />
                       <FormControlLabel value={'externo'} control={<Radio />} label="No, no tengo credenciales UAD" />
        </RadioGroup>
        {datos.ministroUad && <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box sx={{marginTop:'1rem',display:'flex',alignItems:'center'}}>
                    <TextField autoFocus value={datos.documento} onChange={(e)=>processHandleChangeDNI(e)} fullWidth id="ub-dni" inputProps={{maxLength: 9,placeholder:'DNI'}} label="Ingrese su DNI" variant="outlined" />
                    {datos.documento && <Box sx={{paddings:'2rem'}}>
                    <IconButton title='Continuar' disabled={datos.documento.trim()=='' || datos.documento.trim().length<7} variant='contained' color='primary' style={{marginLefts:'1rem'}} onClick={validarDNI}><CheckCircleOutlineIcon /></IconButton>
            </Box>}
            </Box>
        </Box>}
        </>}
        {iniciarBusquedaDatos && <>
        <InscribirSegunTipoMinistro 
            datos={datos} 
            abrirFormConyuge={abrirFormConyuge}
            abrirFormExterno={abrirFormExterno}
            inscribir={()=>{}}
            datosConyugeOk={datosConyugeOk}
            reinicializarConyuge={reinicializarConyuge}
            handleChangeConyugeMinistroUad = {handleChangeConyugeMinistroUad}
            descripcionProvincia={descripcionProvincia}
            datosMinistroUad={datosMinistroUad} 
            cancelar={cancelarBusquedaDatos}/>
        <Pago datos={datos} generarPreferencia={generarPreferencia} id_evento={id_evento} eventoSeleccionado={eventoSeleccionado}/>
    </>
    
}
<FormularioMinistroExterno
            pedirDatosMinExterno = {pedirDatosMinExterno}
            procesarMinistroExterno = {procesarMinistroExterno} 
            cancelarBusquedaDatos={cancelarBusquedaDatos}
        />
</>


}

const Pago = ({datos,generarPreferencia,id_evento,eventoSeleccionado})=>{
    const [productos,setProductos] = React.useState([])
    const [valor,setValor] = React.useState(null)
    const {formatearMontoMoneda,hacerScroll,scrollBottom}= useHelper()
    const [clickReady,setClickReady] = React.useState(false)
    const [pagando,setPagando] = React.useState(false)

    useEffect(()=>{
        setClickReady(false);
        setTimeout(() => {
           setClickReady(true) 
        }, 2000);
    },[valor])

    const handleChangeValor = (event) => {
//        setValor({...datos,email:event.target.value});
        setValor(event.target.value);
    };

   

    const buscarEventosValor = async (id_evento)=>{
        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/eventosvalor/${id_evento}`:`https://uadapi.uad.digital/api/tablasgenerales/eventosvalor/${id_evento}`)
            setProductos(data)
            setValor(data[0]?.id_evento_valor?.toString())
        }catch(err){
            alert(err)
        }
    }

    useEffect(()=>{
        buscarEventosValor(id_evento)
        setTimeout(() => {
            hacerScroll('payment-bottom')
        }, 200);
    },[])

    const handlePago = (id_evento_valor)=>{
        setPagando(true);
        setValor(id_evento_valor)
    }

    return <Box sx={{marginTop:'1rem'}}>

    {<>
    <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">Seleccione el tipo de inscripción:</Typography>
    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',gap:'50px', justifyContent:'center',padding:'50px'}}>    
    {productos.map(item=>{
            return  <Card variant='outlined' style={{width:'300px',textAlign:'center'}}>
            <CardHeader title={item.info_adicional}
                        titleTypographyProps={{
                            variant: "h6",
                            align: "center"
                            ,color:'primary'
                            }}
            />
            <Typography variant="subtitle2">{item.detalle}</Typography>
            <IconButton title='Ir al detalle de la inscripción' size='small' variant='contained' color='primary' onClick={()=>hacerScroll(`item-detalle-${item.id_evento_valor}`)}><InfoOutlinedIcon /></IconButton>
            {/* <CardActions style={{justifyContent:'center'}}>
               <Button onClick={()=>setIdEvento(evento.id_evento)} size="small"  variant="outlined" color='primary'>Inscríbase aquí</Button>
            </CardActions> */}
            <CardContent>
            {clickReady && <Button onClick={()=>handlePago(item.id_evento_valor)} variant='contained'  color='primary'>PAGAR</Button>}
            {!clickReady && <CircularProgress color="primary" /> }
                <Typography style={{ margin:'10px' }} color="text.secondary">$ {formatearMontoMoneda(item.valor_mp)}</Typography>
            </CardContent>
        </Card>
        })}
        </div>
        
        {/* <RadioGroup
                    aria-labelledby="lbl-min-uad"
                    name="radio-min-uad"
                    value={valor}
                    onChange={handleChangeValor}>
                        {
                            productos.map(item=>(
                                <Box>
                                    <FormControlLabel value={item.id_evento_valor.toString()} 
                                    control={<Radio />} label={item.detalle} />
                                    <h3>{item.info_adicional}</h3>
                                    <h2>$ {formatearMontoMoneda(item.valor_mp)}</h2>
                                    {Number(valor)===item.id_evento_valor && clickReady && <Button onClick={()=>setPagando(true)} variant='contained'  color='primary'>PAGAR</Button>}
                                    {Number(valor)===item.id_evento_valor && !clickReady && <CircularProgress color="primary" /> }
                                </Box>
                            ))
                        }
        </RadioGroup>     */}
        {pagando && <Pagando
            item_a_pagar={productos.filter(i=>i.id_evento_valor==Number(valor))[0]}
            abrir={pagando}
            // procesar={()=>alert('Procesar pagando...')}
            cancelar={()=>setPagando(false)}
            venta={datos}
            titulo={eventoSeleccionado.titulo}
            generarPreferencia={generarPreferencia}
        />}
    </>}
    <Box
      id="payment-bottom"
      component="footer"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'rgb(232, 240, 254)',
        height: '50px',
        mt: 'auto', // ensures it sticks to the bottom
      }}
    >
      <Typography variant="body1"> {`${eventoSeleccionado.titulo} | ${eventoSeleccionado.detalles}`} </Typography>
    </Box>
</Box>
}

const InscribirSegunTipoMinistro = ({datos,
    datosMinistroUad,
    descripcionProvincia,
    abrirFormConyuge,
    abrirFormExterno,
    cancelar,
    datosConyugeOk})=>{

if (datos.ministroUad=='ministrouad'){
    return <>

        <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">
            Por favor revise los datos        
        </Typography>

        <Grid container style={{justifyContent:'center'}}>
            <Grid item xs={12} sm={datos.esMatrimonial ? 6 : 12} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <DatosMinistro datosMinistroUad={datosMinistroUad}/>
            </Grid> 
                {datos.esMatrimonial && <Grid item xs={12} sm={6} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <span style={{color:'red'}}>{datosConyugeOk()[1]}</span>
            </Grid>}
            <Box sx={{padding:'1rem',display:'flex',justifyContent:'space-evenly'}}>
            <Button sx={{padding:'2rem'}} onClick={cancelar} variant="outlined" color="error" startIcon={<CloseSharpIcon />}>Cancelar</Button>
                {/* <Button sx={{padding:'2rem'}} onClick={cancelar} variant="outlined" color="error" startIcon={<CloseSharpIcon />}>Cancelar</Button>
                {datosConyugeOk()[0] && <Button sx={{padding:'2rem'}} onClick={inscribir} variant='contained'  color='primary'  startIcon={<CheckIcon />}>{textoSegunTipoParticipante(datos,textoSegunTipoParticipante)}
                </Button>} */}
                {!datosConyugeOk()[0] && <Alert title={datosConyugeOk()[1]} variant="outlined" severity={'warning'}>
                <Box sx={{display:'flex'}}>
                <p>Requiere la inscripción del conyuge</p>
                <Button color='primary' onClick={abrirFormConyuge}>Editar</Button>
            </Box>
            </Alert>
            } 
        </Box>
        </Grid>
        
    </>
    }else{
    return <>

    <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">
    Por favor revise los datos
    </Typography>


    <Grid container style={{justifyContent:'center'}}>
    <Grid item xs={12} sm={datos.esMatrimonial ? 6 : 12} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
    <Externo editar={abrirFormExterno} descripcionProvincia={descripcionProvincia} datos={{edad:datos.edad,nombre:datos.nombre,apellido:datos.apellido,sexo:datos.sexo,id_provincia:datos.id_provincia,documento:datos.documento,email:datos.email}} titulo='Inscribir a:'/>
    </Grid> 
    {datos.esMatrimonial && <Grid item xs={12} sm={6} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
    <span style={{color:'red'}}>{datosConyugeOk()[1]}</span>
    </Grid>}
    <Box sx={{padding:'1rem',display:'flex',justifyContent:'space-evenly'}}>
    <Button sx={{padding:'2rem'}} onClick={cancelar} variant="outlined" color="error" startIcon={<CloseSharpIcon />}>Cancelar</Button>
        {/* <Button sx={{padding:'2rem'}} onClick={cancelar} variant="outlined" color="error" startIcon={<CloseSharpIcon />}>Cancelar</Button>
        {datosConyugeOk()[0] && <Button sx={{padding:'2rem'}} onClick={inscribir} variant='contained'  color='primary'  startIcon={<CheckIcon />}>{textoSegunTipoParticipante(datos,textoSegunTipoParticipante)}
        </Button>} */}
        {!datosConyugeOk()[0] && <Alert title={datosConyugeOk()[1]} variant="outlined" severity={'warning'}>
        <Box sx={{display:'flex'}}>
        <p>Requiere la inscripción del conyuge</p>
        <Button color='primary' onClick={abrirFormConyuge}>Editar</Button>
    </Box>
    </Alert>
    } 
    </Box>
    </Grid>
    </>
}
}

const Pagando = ({abrir,procesar,cancelar,item_a_pagar,venta,generarPreferencia,titulo})=>{
    const [preferencia,setPreferencia] = React.useState(null)
    const [loading,setLoading] = React.useState(false)
    const {formatearMontoMoneda}= useHelper()

    useEffect(()=>{
        setLoading(true);
        setPreferencia(null);
        pagar();
    },[abrir])

    const pagar_old = async ()=>{

        try{
            const {data} = await Axios.post(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/preference/create`:`https://uadapi.uad.digital/api/eventos/preference/create`,venta)
            setTimeout(() => {
                setPreferencia(data);
                setLoading(false);
           }, 2000);
        }catch(err){
            alert(err)
        }
    }

    const pagar = async ()=>{
       const preferencia_de_api = await generarPreferencia(item_a_pagar.id_evento_valor);
       setTimeout(() => {
            setPreferencia(preferencia_de_api);
            //setLoading(false);
   }, 2000);
}

    const onReady = ()=>{
            setLoading(false);
    }

    const onError = (e)=>{
        console.log(e)
        alert('Se produjo un error al inicializar el pago. Vuelva a intentarlo más tarde.')
    }

    return <Dialogos open={abrir} 
                        fullscreen={false}
                        backdropProps={{
                            style: {
                              backgroundColor: 'gray'
                            },
                          }}
                        style={{width:'500px',minWidth:'500px',marginRight:'auto',marginLeft:'auto'}}
                        procesarResultado={procesar}
                        procesarCancelar = {cancelar || false}
                       >
                    <p>{JSON.stringify(venta)}</p>
                    <InscribirSegunTipoMinistro
                    descripcionProvincia="pepe"
                    datos={venta}
                    datosMinistroUad={{}}
                    datosConyugeOk={[true]}
                    abrirFormExterno={()=>alert('ddd')}
                    />
                     <Box sx={{display:'flex',flexDirection:'column',textAlign:'center'}}>
                        <Typography align='center' variant="h5">{titulo}</Typography>
                        <Typography align='center' variant="subtitle1">{item_a_pagar.detalle}</Typography>
                                    <h2>$ {formatearMontoMoneda(item_a_pagar.valor_mp)}</h2>
                    </Box>
                  {loading && <CircularProgress color="primary" /> }
                  <div style={{minWidth:'300px'}}>
                      {preferencia?.id && <Wallet  onReady={onReady} onError={onError} initialization={{  
                        preferenceId: preferencia.id,
                        redirectMode:'self' }} locale = 'es-AR'
                        customization={{ texts:{ valueProp: 'smart_option'}}} />}
                  </div>
    </Dialogos>
    }

const FormularioMinistroExterno = ({pedirDatosMinExterno,procesarMinistroExterno,cancelarBusquedaDatos})=>{
    return <Dialogos open={pedirDatosMinExterno} 
                        titulo='Ingrese sus datos personales' 
                        procesarResultado={procesarMinistroExterno}
                        procesarCancelar = {cancelarBusquedaDatos || false}
                       >
                  <DatosPersonalesMinExterno/>
    </Dialogos>
    }


    const DatosMinistro = ({datosMinistroUad,editar})=>{
        return <Grid container style={{border:'solid 1px lightgray',borderRadius:'10px',maxWidth:'350px',justifyContent:'center',padding:'0.5rem'}}>
        
        <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px gray'}}>
                <Box sx={{display:'flex',justifyContent:'space-between',marginBottom:'0.5rem'}}>
                    <Typography style={{}} variant="h6">
                        Inscribir a:
                    </Typography>
                    {editar && <Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}>Editar</Button>}
                </Box>
        </Grid>                     
        <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                <Box sx={{display:'flex'}}>
                    <PersonIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                    <Typography variant='subtitle1'>{datosMinistroUad.nombre.toUpperCase()}</Typography>
                </Box>
        </Grid> 
        <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
            <Box sx={{display:'flex'}}>
                <BadgeIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                <Typography variant="subtitle1">{datosMinistroUad.nro_documento}</Typography>
            </Box>
        </Grid> 
        <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
            <Box sx={{display:'flex'}}>
                <NumbersIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                <Typography variant="subtitle1">{datosMinistroUad.edad!='' ? `${datosMinistroUad.edad} años` : ''}</Typography>
            </Box>
        </Grid> 
        <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
            <Box sx={{display:'flex'}}>
                <LocationOnIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                <Typography variant="subtitle1"> {datosMinistroUad.provincia}</Typography>
            </Box>
        </Grid> 
        <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
            <Box sx={{display:'flex'}}>
                <AlternateEmailIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                <Typography variant="subtitle1"> {datosMinistroUad.email}</Typography>
            </Box>
        </Grid>
    </Grid>
    }
    
    const textoSegunTipoParticipante = (datos,tipoParticipante) =>{

        if (tipoParticipante=='general'){
            return `Los datos son correctos`
        }else{
            if(datos.contingente==1){
                return `Los datos son correctos`
            }else if (datos.contigente==0){
                return `Los datos son correctos`
            }else{
                return `Continuar`
            }
        }
    }

    const Externo = ({datos,descripcionProvincia,editar,titulo})=>{
        return <>
            <Grid container style={{border:'solid 1px lightgray',borderRadius:'10px',maxWidth:'300px',justifyContent:'center',padding:'0.5rem'}}>
                <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px gray'}}>
                    <Box sx={{display:'flex',justifyContent:'space-between',marginBottom:'0.5rem'}}>
                        <Typography style={{}} variant="h6">
                            {titulo}
                        </Typography>
                        {/*<Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}>Editar</Button>*/}
                    </Box>
                </Grid>  
                   <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                            <PersonIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                            <Typography variant='subtitle1'>{`${datos.nombre} ${datos.apellido}`}</Typography>
                            <Button onClick={editar} size='small' variant='outlined' color='primary' startIcon={<EditIcon/>}>Editar</Button>
                        </Box>
                   </Grid> 
                   <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                        <Box sx={{display:'flex'}}>
                            <BadgeIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                            <Typography variant="subtitle1">{datos.documento}</Typography>
                        </Box>
                   </Grid> 
                   <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                        <Box sx={{display:'flex'}}>
                            <NumbersIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                            <Typography variant="subtitle1">{datos.edad!='' ? `${datos.edad} años` : ''}</Typography>
                        </Box>
                   </Grid>                
                   <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                       <Box sx={{display:'flex'}}>
                            <LocationOnIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                            <Typography variant="subtitle1">{descripcionProvincia(datos.id_provincia)}</Typography>
                        </Box>
                   </Grid> 
                   <Grid item xs={12} style={{textAlign:'left',borderBottom:'solid 1px lightgray'}}>
                        <Box sx={{display:'flex'}}>
                            <AlternateEmailIcon sx={{marginRight:'0.5rem'}} color='primary' fontSize='small'/>
                            <Typography variant="subtitle1"> {datos.email}</Typography>
                        </Box>
                   </Grid>
              </Grid> 
        </>
    }