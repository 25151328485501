import React, { useEffect, useState} from 'react'
import {Box,Button,CardActions,Typography,CardContent,Card,CardHeader,CardMedia,Grid} from "@material-ui/core";
import BuildCircleIcon from '@mui/icons-material/Security';
import HomeIcon from '@mui/icons-material/Home';
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import {Ingreso} from './Ingreso'
import {Loader} from './Loader'
import CryptoJS from 'crypto-js'
import { Margin, Padding } from '@mui/icons-material';
import { NuevaInscripcion } from './NuevaInscripcion';
import {Dialogos} from './Dialogos'
import { Precios } from './Precios';
import {useHelper} from '../hooks/useHelper';
import Stack from '@mui/material/Stack';

export const Eventos = ()=>{
    const [eventos,setEventos] = useState([])
    const [tienePermiso,setTienePermiso] = useState(null)
    const [codigoEsValido,setCodigoEsValido] = useState(true)
//    const [codigoEsValido,setCodigoEsValido] = useState(null)
    const [datosUsuario,setDatosUsuario] = useState(null)
    const [cargando,setCargando]= useState(true)
    const parametros = useParams()
    const [idEvento,setIdEvento] = useState(null)
    const [eventoSeleccionado,setEventoSeleccionado]=useState(null)
    const {hacerScroll} = useHelper()

    useEffect(()=>{
        const obtenerEventos = async ()=>{
            try{
                const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/documentos/events`:`https://uadapi.uad.digital/documentos/events`)
                setEventos(data) 
                setCargando(false)
              
            }catch(err){
                console.log('Error loading events',err)
                setCargando(false)
            }
           
        }

        obtenerEventos()
    },[])

    useEffect(()=>{
        if(idEvento){
            const evento = eventos.filter(i=>i.id_evento===idEvento)[0];
            setEventoSeleccionado(evento);
            setTimeout(() => {
                hacerScroll("evento-inicio")
            }, 200);
        }else{
            setEventoSeleccionado(null);
        }
    },[idEvento])

    const cancelarEvento = ()=>{
        setIdEvento(null)
    }

    const getTitulo = ()=>{
        if(idEvento){
            return eventos.filter(e=>e.id_evento===idEvento)[0].titulo
        }else{
            return ''
        }
    }

    if (cargando){
        return  <Loader open={true} 
        texto='Cargando...'/>
    }

    return <div>
    <Dialogos open={eventoSeleccionado} 
                    textoCancelar={'Volver'}
                    fullscreen={true}
                    procesarCancelar = {cancelarEvento}
                   >

                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>

                <Button id="evento-inicio" onClick={cancelarEvento} style={{width:'100px'}} variant="outlined" startIcon={<HomeIcon />}>
                    Inicio
                </Button>
                {eventoSeleccionado && 
                        <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={eventoSeleccionado?.titulo}
                    subheader={eventoSeleccionado.subtitulo}
                    titleTypographyProps={{ align: 'center', variant: 'h4' }}
                    subheaderTypographyProps={{ align: 'center', variant: 'h6', color: 'textSecondary' }}
                  />
                  <Typography variant="subtitle2" align="center">
                      {eventoSeleccionado.detalles}
                  </Typography>
                  <CardMedia
                    component="img"
                    height="300"
                    image={eventoSeleccionado?.flyer_web}
                    alt={eventoSeleccionado?.titulo}
                  />
                  <CardContent>
                  
                    <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                      {eventoSeleccionado.texto1}
                    </Typography>
                    <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                      {eventoSeleccionado.texto2}
                    </Typography>
                    <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                      {eventoSeleccionado.texto3}
                    </Typography>
                    <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                      {eventoSeleccionado.texto4}
                    </Typography>
                  </CardContent>
                </Card>
                <Precios id_evento={idEvento}/>
        
              </Grid>
                        </Grid>
                        }
                    </Box>
                {eventoSeleccionado && <NuevaInscripcion id_evento={idEvento} eventoSeleccionado={eventoSeleccionado}/>}
    </Dialogos>
    <Box sx={{display:'flex',flexDirection:'column',padding:'20px'}}>
    <Typography variant="h4" align="left" sx={{margin:'10px'}}>
                      Próximos eventos:
    </Typography>
            <div style={{minHeight:'350px',
                        background:'',
                        display:'flex',
                        flexDirection:'wrap',
                        flexWrap:'wrap',
                        gap:'30px',
                        justifyContent:'center',
                        padding:'10px'}}>            
           {eventos.filter((i)=>i.cod_ref_mp==='sin_cod_ref_mp').map((evento,index)=>{
            return  <Card variant='outlined' style={{width:'350px'}}>
            <img src={evento.flyer_web} alt="evento" style={{width:'350px',height:'150px'}} />

            <CardHeader title={evento.titulo}
                        titleTypographyProps={{
                            variant: "h6",
                            align: "center"
                            ,color:'primary'
                            }}
            />
            <Typography variant="subtitle2">{evento.detalles}</Typography>
            <CardActions style={{justifyContent:'center'}}>
               <Button onClick={()=>setIdEvento(evento.id_evento)} size="small"  variant="outlined" color='primary'>Inscríbase aquí</Button>
            </CardActions>
            <CardContent>
                <Typography style={{ margin:'10px' }} color="text.secondary">{evento.descripcion}</Typography>
            </CardContent>
        </Card>
        })}
        </div>
        </Box>
</div>
    
}