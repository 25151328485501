import React, { useEffect, useState} from 'react'
import {Box,Button,TextField,Typography} from "@material-ui/core";
import BuildCircleIcon from '@mui/icons-material/Security';
import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import {Ingreso} from './Ingreso'
import {Loader} from './Loader'
import CryptoJS from 'crypto-js'

export const Colaborador = ()=>{
    const [usuario,setUsuario] = useState(null)
    const [tienePermiso,setTienePermiso] = useState(null)
    const [codigoEsValido,setCodigoEsValido] = useState(true)
//    const [codigoEsValido,setCodigoEsValido] = useState(null)
    const [datosUsuario,setDatosUsuario] = useState(null)
    const [cargando,setCargando]= useState(true)
    const parametros = useParams()

    useEffect(()=>{
        const validarId = async ()=>{
            try{
                const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/colaborador/acceso/${parametros.id}`:`https://uadapi.uad.digital/api/eventos/colaborador/acceso/${parametros.id}`)
                
                console.log('palabra',data.palabra)
                
                var bytes  = CryptoJS.AES.decrypt(data.palabra, 'eureca700');
                var originalText = bytes.toString(CryptoJS.enc.Utf8);
                console.log('originalText',originalText)

                if (data?.resultado===1){
                    setCodigoEsValido(true)
                    setCargando(false)
                }else{
                    setCodigoEsValido(false)
                    setCargando(false)
                }
            }catch(err){
                setCodigoEsValido(false)
                alert(err?.response?.data?.message || 'Error de validación 1')
                setCargando(false)
            }
           
        }

        validarId()
    },[parametros.id])

    const validarUsuario = async ()=>{
        try{
            debugger
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/colaborador/credencial/${parametros.id}/${usuario}`:`https://uadapi.uad.digital/api/eventos/colaborador/credencial/${parametros.id}/${usuario}`)
            if (data?.nombre){
                setTienePermiso(true)
                setDatosUsuario(data)
            }else{
                setTienePermiso(false)
                setDatosUsuario(null)
            }
        }catch(err){
            console.log(err)
            setTienePermiso(false)
            alert(err?.response?.data?.message || 'Error de validación 2')
        }
    }
    

    if (cargando){
        return  <Loader open={true} 
        texto='Cargando...'/>
    }

    if (!codigoEsValido){
        return <h1>El link no es válido</h1>
    }

    if (tienePermiso===true){
        return <Box>
                <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Hola {datosUsuario?.nombre} !</Typography>
                <Ingreso codigo={parametros.id} usuario={datosUsuario}/>
        </Box>  
    }

    return <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        
        <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Hola colaborador!</Typography>
        <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Para continuar por favor ingresá tu nombre de usuario:</Typography>
        <br/>
        <BuildCircleIcon sx={{ fontSize: 80 }} />
        <br/>
        <TextField value={usuario} placeholder="Tu nombre de usuario" onChange={(e)=>setUsuario(e.target.value)} type="text"/>
        {usuario && <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
            <Button variant="contained" color="primary" onClick={validarUsuario}>Continuar</Button>
        </Box>}
        {tienePermiso===false && <Alert style={{fontSize:'large'}} severity="warning" variant=''>Su nombre de usuario no es válido</Alert> }
</div>

}