import React, { useEffect, useState} from 'react'
import {Box,Button,CardActions,Typography,CardContent,Card,CardHeader,Grid,List,ListItem,ListItemIcon,ListItemText} from "@material-ui/core";
import BuildCircleIcon from '@mui/icons-material/Security';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import {Ingreso} from './Ingreso'
import {Loader} from './Loader'
import CryptoJS from 'crypto-js'
import { Margin, Padding } from '@mui/icons-material';
import { NuevaInscripcion } from './NuevaInscripcion';
import {Dialogos} from './Dialogos'
import {useHelper} from '../hooks/useHelper'

export const Precios = ({id_evento})=>{
    const [productos,setProductos] = React.useState([])
    const [valor,setValor] = React.useState(null)
    const {formatearMontoMoneda}= useHelper()
    const [clickReady,setClickReady] = React.useState(false)
    const [pagando,setPagando] = React.useState(false)

    const buscarEventosValor = async (id_evento)=>{
        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/eventosvalor/${id_evento}`:`https://uadapi.uad.digital/api/tablasgenerales/eventosvalor/${id_evento}`)
            setProductos(data)
            setValor(data[0]?.id_evento_valor?.toString())
        }catch(err){
            alert(err)
        }
    }

    useEffect(()=>{
        buscarEventosValor(id_evento)
    },[])

    return <Grid container spacing={2} justifyContent="center">
    {productos.map((item, index) => (
      <Grid item key={index} xs={12} md={6}>
        <Card>
          <CardHeader
            id={`item-detalle-${item.id_evento_valor}`}
            title={item.info_adicional}
            titleTypographyProps={{ align: 'center' }}
          />
          <CardContent>
            <Typography variant="body1" align="center">
              {item.detalle}
            </Typography>
            <Typography variant="h6" align="center" color="primary">
                $ {formatearMontoMoneda(item.valor_mp)}
            </Typography>
            <List>
                <ListItem key={`${index}t1`}>
                        <ListItemIcon>
                            <CheckCircleIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={item?.texto1} />
                </ListItem>
                <ListItem key={`${index}t2`}>
                        <ListItemIcon>
                            <CheckCircleIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={item?.texto2} />
                </ListItem>
                <ListItem key={`${index}t3`}>
                        <ListItemIcon>
                            <CheckCircleIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={item?.texto3} />
                </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>

  /*
  <List>
                {option.details.map((detail, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={detail} />
                  </ListItem>
                ))}
              </List>
  
  */

    return <Box sx={{marginTop:'1rem'}}>

    {<>
    <Typography style={{marginTop:'1rem',marginBottom:'1rem'}} variant="h6">Precios:</Typography>
    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',gap:'50px', justifyContent:'center'}}>    
    {productos.map(item=>{
            return  <Card variant='outlined' style={{width:'300px'}}>
            <CardHeader title={item.info_adicional}
                        titleTypographyProps={{
                            variant: "h6",
                            align: "center"
                            ,color:'primary'
                            }}
            />
            <Typography variant="subtitle2">{item.detalle}</Typography>
            <CardContent>
                <Typography style={{ margin:'10px' }} color="text.secondary">$ {formatearMontoMoneda(item.valor_mp)}</Typography>
                <Alert>
                    {item?.texto1}
                </Alert>
                <Alert>
                    {item?.texto2}
                </Alert>
                <Alert>
                    {item?.texto3}
                </Alert>
                <Alert>
                    {item?.texto4}
                </Alert>
            </CardContent>
        </Card>
        })}
        </div>
    </>}
    
    
</Box>
}